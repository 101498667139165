import { BaseItem, MetaInfo } from '@/components/shared/model/BaseItem';
import { Icon } from '@/components/elemental/icon/Icon';
import { Image } from '@/components/elemental/image/Image';
import { ImageGallery } from '@/components/elemental/imageGallery/ImageGallery';
import { Button } from '@/components/elemental/button/Button';
import { Text } from '@/components/elemental/text/Text';
import { Location } from '@/components/elemental/location/Location';
import { Spacer } from '@/components/elemental/spacer/Spacer';
import { Video } from '@/components/elemental/video/Video';
import { CustomWidget } from '@/components/custom/customWidget/CustomWidget';
import { Container } from '@/components/elemental/container/Container';
import { Layout } from '@/components/elemental/layout/Layout';
import { Collection } from '@/components/elemental/collection/Collection';
import { FluidContainer } from '@/components/elemental/fluidContainer/FluidContainer';
import { AppBar } from '@/components/app/appBar/AppBar';
import { PageRef } from '@/components/page/pageRef/PageRef';
import { PropertyString } from '../property/propertyString/PropertyString';
import { ProductRef } from '@/components/product/productRef/ProductRef';
import { Product } from '@/components/product/product/Product';
import { App } from '@/components/app/app/App';
import { Page } from '@/components/page/page/Page';
import { PageNode } from '@/components/page/pageNode/PageNode';

export const appMeta = {typeName: "App", friendlyName: "App"};
export const collectionMeta = {typeName: "Collection", friendlyName: "Collection"}

const typeToItemBuilder = new Map<string, Function>([
  [appMeta.typeName, ()=> new App()],
  [Page.meta.typeName, ()=> new Page()],
  [Container.meta.typeName, ()=> new Container()],
  [Layout.meta.typeName, ()=> new Layout()],
  [collectionMeta.typeName, ()=> new Collection()],
  [FluidContainer.meta.typeName, ()=> new FluidContainer()],
  [AppBar.meta.typeName, ()=> new AppBar()],
  [PageRef.meta.typeName, ()=> new PageRef()],
  [PageNode.meta.typeName, ()=> new PageNode()],
  [PropertyString.meta.typeName, ()=> new PropertyString()],
  [Product.meta.typeName, ()=> new Product()],
  [ProductRef.meta.typeName, ()=> new ProductRef()],
  [Icon.meta.typeName, ()=> new Icon()],
  [Image.meta.typeName, ()=> new Image()],
  [ImageGallery.meta.typeName, ()=> new ImageGallery()],
  [Text.meta.typeName, ()=> new Text()],
  [Spacer.meta.typeName, ()=> new Spacer()],
  [Button.meta.typeName, ()=> new Button()],
  [Location.meta.typeName, ()=> new Location()],
  [Video.meta.typeName, ()=> new Video()],
  [CustomWidget.meta.typeName, ()=> new CustomWidget()]
]);

export
const typeToItem = (typeName: string): BaseItem => {
  const builder = typeToItemBuilder.get(typeName);
  if(builder==undefined){
    throw new Error("typeToItemBuilder: typename not found: " + typeName);
  }
  return builder();
};