import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectItemArea = _resolveComponent("SelectItemArea")!

  return (!$setup.refContext.mode.inAnyEditMode())
    ? _renderSlot(_ctx.$slots, "body", { key: 0 }, undefined, true)
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        ondragstart: "return false;",
        class: _normalizeClass(["BlockContainer", $setup.props.fitContent ? 'FitContent' : 'InheritSize'])
      }, [
        _renderSlot(_ctx.$slots, "body", {}, undefined, true),
        _createVNode(_component_SelectItemArea, {
          item: $setup.refItem,
          context: $setup.refContext
        }, null, 8, ["item", "context"])
      ], 2))
}