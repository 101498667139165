<script lang="ts">
import { onMounted, Ref, ref, watch } from 'vue';
import AppRenderer from './AppRenderer.vue';
// import { useRoute } from 'vue-router';
import { App } from '@/components/app/app/App';
import { useServerDelegate } from '@/shared/UseServerDelegate';
import { useRoute } from 'vue-router';

export default {
  name: 'AppRendererDelegate',
  components: {
    AppRenderer,
  },
  setup(){
    const refUserId = ref("unknown");
    const refAppId = ref("unknown");

    const route = useRoute();
    watch(
      () => route.params.userId,
      () => {
        refUserId.value = route.params.userId as string;
      },
      { immediate: true }
    );
    watch(
      () => route.params.appId,
      () => {
        refAppId.value = route.params.appId as string;
      },
      { immediate: true }
    );

    const refApp = ref(null) as Ref<null|App>;

    onMounted(()=>{
      const {getApp} = useServerDelegate();
      getApp(
        refUserId.value,
        refAppId.value,
        (value)=>{
          const app = new App();
          app.fromStoreObject(value);
          // Wait for next event cycle
          setTimeout(()=>{
            refApp.value = app;
            refAppId.value = refApp.value.ref;
          });

        },
        (error)=>{
          console.error('Error:', error);
        }
      );
    })

    return {
      refApp,
      refAppId,
    };
  },
}
</script>

<template>
  <AppRenderer v-if="refApp" :app="refApp" :key="refAppId"/>
  <!-- <p v-else> App with id = {{refAppId}} not found </p> -->
</template>

<style scoped>
</style>