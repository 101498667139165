import {createRouter, createWebHistory, useRoute} from 'vue-router'

import AppRendererDelegate from './appRenderer/view/AppRendererDelegate.vue';
import { defineAsyncComponent } from 'vue';

const routes = [
  {
    path: '/:userId/:appId',
    component: AppRendererDelegate
  },
  {
    path: '/:userId/:appId/:pageId',
    component: AppRendererDelegate
  },
  {
    path: '/editor/:userId/:appId',
    component: defineAsyncComponent({
      loader: () => import('./appEditor/view/AppEditorDelegate.vue'),
    })
  },
  {
    path: '/editor/:userId/:appId/:pageId',
    component: defineAsyncComponent({
      loader: () => import('./appEditor/view/AppEditorDelegate.vue'),
    })
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;

export const getPathForPage = (pageId: string) : string => {
  if (pageId === undefined) return ""; //TODO: improve

  // Internal link starts with '/'
  const n = pageId.lastIndexOf('/');
  pageId = pageId.substring(n + 1);

  const params = useRoute().params;
  return `/${params.userId}/${params.appId}/${pageId}`;
}

export const ifInternal = (url: string) => {
  return url.startsWith("/");
};