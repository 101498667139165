import { defineAsyncComponent } from 'vue'

import { BaseItem, MetaInfo } from "./shared/model/BaseItem";
import Context from "./shared/view/Context"

import { App } from "./app/app/App";
import { Collection } from "./elemental/collection/Collection";
import { Container } from './elemental/container/Container';
import { CustomWidget } from './custom/customWidget/CustomWidget';
import { FluidContainer } from './elemental/fluidContainer/FluidContainer';
import { AppBar } from './app/appBar/AppBar';
import { Icon } from "./elemental/icon/Icon";
import { Image } from "./elemental/image/Image";
import { Layout } from './elemental/layout/Layout';
import { Page } from "./page/page/Page";
import { PageNode } from './page/pageNode/PageNode';
import { PageRef } from './page/pageRef/PageRef';
import { Product } from './product/product/Product';
import { ProductRef } from './product/productRef/ProductRef';
import { ProductPreview } from './product/productPreview/ProductPreview';
import { ProductGallery } from './product/productGallery/ProductGallery';
import { Text } from "./elemental/text/Text";
import { Spacer } from './elemental/spacer/Spacer';
import { Button } from "./elemental/button/Button";
import { Location } from "./elemental/location/Location";
import { Video } from "./elemental/video/Video";

import { StyleVariableString } from './shared/style/StyleVariableString';
import { StyleVariableColor } from './shared/style/StyleVariableColor';
import { StyleVariableFontFamily } from './shared/style/StyleVariableFontFamily';

import AppFactory from "./app/app/view/ViewFactory";
import CollectionFactory from "./elemental/collection/view/ViewFactory";
import ContainerFactory from "./elemental/container/view/ViewFactory";
import CustomWidgetFactory from "./custom/customWidget/view/ViewFactory";
import FluidContainerFactory from "./elemental/fluidContainer/view/ViewFactory";
import AppBarFactory from "./app/appBar/view/ViewFactory";
import IconFactory from "./elemental/icon/view/ViewFactory";
import ImageFactory from "./elemental/image/view/ViewFactory";
import ImageGalleryFactory from "./elemental/imageGallery/view/ViewFactory";
import LayoutFactory from "./elemental/layout/view/ViewFactory";
import TextFactory from "./elemental/text/view/ViewFactory";
import SpacerFactory from "./elemental/spacer/view/ViewFactory";
import ButtonFactory from "./elemental/button/view/ViewFactory";
import LocationFactory from "./elemental/location/view/ViewFactory";
import PageFactory from "./page/page/view/ViewFactory";
import PageNodeFactory from "./page/pageNode/view/ViewFactory";
import PageRefFactory from "./page/pageRef/view/ViewFactory";
import ProductFactory from "./product/product/view/ViewFactory";
import ProductRefFactory from "./product/productRef/view/ViewFactory";
import ProductPreviewFactory from "./product/productPreview/view/ViewFactory";
import ProductGalleryFactory from "./product/productGallery/view/ViewFactory";
import VideoFactory from "./elemental/video/view/ViewFactory";

import BaseViewFactory from './shared/view/BaseViewFactory';

import { BaseStyle } from './shared/model/BaseStyle';
import StylePadding from './shared/style/StylePadding';
import StyleContained from './shared/style/StyleContained';
import StyleBorder from './shared/style/StyleBorder';
import StyleFont from './shared/style/StyleFont';
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { PropertyUrl } from "@/components/shared/property/propertyUrl/PropertyUrl";
import { PropertyCode } from "@/components/shared/property/propertyCode/PropertyCode";
import { PropertyUrlUnique } from './shared/property/propertyUrlUnique/PropertyUrlUnique';
import { ImageGallery } from './elemental/imageGallery/ImageGallery';

const factories = new Map<MetaInfo, BaseViewFactory>([
  [App.meta, new AppFactory],
  [Collection.meta, new CollectionFactory],
  [Container.meta, new ContainerFactory],
  [CustomWidget.meta, new CustomWidgetFactory],
  [FluidContainer.meta, new FluidContainerFactory],
  [AppBar.meta, new AppBarFactory],
  [Icon.meta, new IconFactory],
  [Image.meta, new ImageFactory],
  [ImageGallery.meta, new ImageGalleryFactory],
  [Layout.meta, new LayoutFactory],
  [Page.meta, new PageFactory],
  [PageNode.meta, new PageNodeFactory],
  [PageRef.meta, new PageRefFactory],
  [Product.meta, new ProductFactory],
  [ProductRef.meta, new ProductRefFactory],
  [ProductPreview.meta, new ProductPreviewFactory],
  [ProductGallery.meta, new ProductGalleryFactory],
  [Text.meta, new TextFactory],
  [Spacer.meta, new SpacerFactory],
  [Button.meta, new ButtonFactory],
  [Location.meta, new LocationFactory],
  [Video.meta, new VideoFactory],
]);

export default class ComponentFactory{
  static getComponent(item: BaseItem, context: Context): any{
    const factory = factories.get(item.getMeta());
    if (factory != undefined){
      return factory.getComponent(item, context);
    }

    if (item.getMeta() == PropertyString.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/property/propertyString/PropertyString.vue'),
      });
    }

    if (item.getMeta() == PropertyUrl.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/property/propertyUrl/PropertyUrl.vue'),
      });
    }

    if (item.getMeta() == PropertyCode.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/property/propertyCode/PropertyCode.vue'),
      });
    }

    if (item.getMeta() == PropertyUrlUnique.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/property/propertyUrlUnique/PropertyUrlUnique.vue'),
      });
    }

    if (item.getMeta() == StyleVariableString.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/style/view/EditorStyleVariableString.vue'),
      });
    }
    if (item.getMeta() == StyleVariableColor.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/style/view/EditorStyleVariableColor.vue'),
      });
    }
    if (item.getMeta() == StyleVariableFontFamily.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/style/view/EditorStyleVariableFontFamily.vue'),
      });
    }
    if (item.getMeta() == StylePadding.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/style/view/EditorStylePadding.vue'),
      });
    }
    if (item.getMeta() == StyleContained.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/style/view/EditorStyleContained.vue'),
      });
    }
    if (item.getMeta() == StyleBorder.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/style/view/EditorStyleBorder.vue'),
      });
    }
    if (item.getMeta() == StyleFont.meta){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/style/view/EditorStyleFont.vue'),
      });
    }
    if (item as BaseStyle){
      return defineAsyncComponent({
        loader: () => import('@/components/shared/util/GenericDefinitionEditor.vue'),
      });
    }
    throw new Error("ComponentFactory.getComponent: item type not found.");
  }

  static getViewFactoryStylesForItem(item: BaseItem): string[]{
    if (factories.has(item.getMeta()))
      return factories.get(item.getMeta())!.getNameOfRegisteredStyles();
    throw new Error("ComponentFactory.getViewFactoryStylesForItem: no item type found.")
  }

  static async getStyleForItem(item: BaseItem, styleName: string){
    if (factories.has(item.getMeta()))
      return factories.get(item.getMeta())!.getStyleByName(styleName);
    throw new Error("ComponentFactory.getStyleForItem: no item type found.")
  }
}