<script lang="ts">
import MixinProps from '@/components/shared/view/MixinProps';
import SelectItemArea from '@/components/shared/util/SelectItemArea.vue';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { BaseItem } from '../model/BaseItem';

export default {
  mixins: [MixinProps],
  props: {
    fitContent: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  name: "BlockContainer",
  components: {
    SelectItemArea
  },
  setup(props: any) {
    const { getRefItem, getRefContext } = useAddReactivity();
    const refItem = getRefItem<BaseItem>(props);
    const refContext = getRefContext(props);

    return {
      props,
      refItem,
      refContext
    };
  },
};
</script>

<template>
  <slot name="body" v-if="!refContext.mode.inAnyEditMode()"/> <!-- TODO: what about props.fitContent? -->

  <div v-else ondragstart="return false;" class="BlockContainer" :class="props.fitContent ? 'FitContent' : 'InheritSize'">
    <slot name="body"/>
    <SelectItemArea :item="refItem" :context="refContext"/>
  </div>
</template>

<style scoped>
.BlockContainer{
  position: relative;
}
</style>