import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "App-MenuPopup ZIndexMenuPopup Unselectable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!
  const _component_BlockContainer = _resolveComponent("BlockContainer")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ComponentContainer, {
      item: $setup.refApp,
      context: $setup.refContext
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", {
          class: "App",
          style: _normalizeStyle({backgroundColor: $setup.refContext.selection.app!.theme.getPaletteColor($setup.PaletteColor.B1).getValue()})
        }, [
          _createElementVNode("div", {
            class: "App-Page",
            style: _normalizeStyle([{"position":"absolute","z-index":"0","bottom":"0","left":"0","right":"0"}, {top: $setup.refPageTop}])
          }, [
            ($setup.refContext.selection.page)
              ? (_openBlock(), _createBlock(_component_ComponentWrapper, {
                  item: $setup.refContext.selection.page,
                  context: $setup.refContext,
                  key: $setup.refPageId
                }, null, 8, ["item", "context"]))
              : _createCommentVNode("", true)
          ], 4),
          _createElementVNode("div", {
            class: "App-Bar",
            style: _normalizeStyle([{position: $setup.refAppBarPosition}, {"z-index":"1"}])
          }, [
            _createVNode(_component_BlockContainer, {
              item: $setup.refApp.appBar,
              context: $setup.refContext
            }, {
              body: _withCtx(() => [
                _createVNode(_component_ComponentWrapper, {
                  item: $setup.refApp.appBar,
                  context: $setup.refContext
                }, null, 8, ["item", "context"])
              ]),
              _: 1
            }, 8, ["item", "context"])
          ], 4)
        ], 4)
      ]),
      _: 1
    }, 8, ["item", "context"]),
    ($setup.refShowMenuPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "InheritSize",
            style: _normalizeStyle([{"display":"flex","flex-direction":"column","justify-content":"center","align-items":"center","gap":"20px"}, {...$setup.refStyleAppBar.contained.getBackgroundFillPredefinedStyleObject($setup.refContext)}])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refContext.selection.app.pageNodes.getElements(), (pageNode) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                class: "GlobalLink",
                style: _normalizeStyle($setup.getButtonStyle()),
                key: pageNode.ref,
                to: {path: $setup.getPathForPage(pageNode.url.getValue()), query: _ctx.$route.query},
                onClick: _cache[0] || (_cache[0] = ($event: any) => {$setup.refContext.uiState.requestMenuPopup(false);})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(pageNode.titleNavigation.getValueOf($setup.refContext.languageOption)), 1)
                ]),
                _: 2
              }, 1032, ["style", "to"]))
            }), 128)),
            _createElementVNode("button", {
              class: "GlobalFontIconButton",
              style: _normalizeStyle($setup.getCancelButtonStyle()),
              onClick: _cache[1] || (_cache[1] = ($event: any) => {$setup.refContext.uiState.requestMenuPopup(false);})
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "xmark" })
            ], 4)
          ], 4)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}