import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StyleBorder from "@/components/shared/style/StyleBorder";
import StyleContained from "@/components/shared/style/StyleContained";
import StylePadding from "@/components/shared/style/StylePadding"
import StyleFont from "@/components/shared/style/StyleFont";
import { StyleVariableColor } from "@/components/shared/style/StyleVariableColor";
import { EnumPair, StyleVariableString } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";
import { PaletteColor } from "@/components/shared/theme/PaletteSet";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Text Style"};

  public font = new StyleFont;
  public contained = new StyleContained;

  public getStoreObjectItem(): Object{
    return {
      font: this.font.getStoreObject(),
      contained: this.contained.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.font.fromStoreObject(item.font);
    this.contained.fromStoreObject(item.contained);
  }

  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.contained.padding.top.setEnum("M");
    this.contained.padding.bottom.setEnum("M");
    this.contained.padding.left.setEnum("M");
    this.contained.padding.right.setEnum("M");

    this.font.color.setStyleVariantName(PaletteColor.F1);
  }

  from(item: BaseItem): void{
    super.from(item);
    this.setStyleName((item as BaseStyle).getStyleName());
    this.font.from((item as Style).font);
    this.contained.from((item as Style).contained);
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    throw new Error("Text default style getPredefinedStyleObject() not implemented");
  }
}