import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { PropertyUrl } from "@/components/shared/property/propertyUrl/PropertyUrl";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";
import { Icon } from "@/components/elemental/icon/Icon";

export
class Button extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Button", friendlyName: "Button"};

  static readonly nameTitle: string = "Title";
  static readonly nameLink: string = "URL";
  static readonly nameIcon: string = "Icon";

  title = new PropertyString(Button.nameTitle);
  link = new PropertyUrl(Button.nameLink);
  icon = new Icon(Button.nameIcon);

  public getStoreObjectItem(): Object{
    return {
      title: this.title.getStoreObject(),
      link: this.link.getStoreObject(),
      icon: this.icon.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);
    this.link.fromStoreObject(item.link);
    this.icon.fromStoreObject(item.icon);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.title.setValueOf(Language.universal, "Title");
    this.link.setValueOf(Language.universal, "https://google.com");

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const button = item as Button;
    this.title.from(button.title);
    this.link.from(button.link);
    this.icon.from(button.icon);
  }

  getMeta(): MetaInfo{
    return Button.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Button();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}