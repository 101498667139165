import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { PropertyUrl } from "@/components/shared/property/propertyUrl/PropertyUrl";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Image extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Image", friendlyName: "Image"};

  static readonly nameAltText: string = "Alt Text";
  static readonly nameUrl: string = "URL";
  static readonly nameLink: string = "Link";

  url = new PropertyUrl(Image.nameUrl);
  altText = new PropertyString(Image.nameAltText);
  link = new PropertyUrl(Image.nameLink);

  public getStoreObjectItem(): Object{
    return {
      url: this.url.getStoreObject(),
      altText: this.altText.getStoreObject(),
      link: this.link.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.url.fromStoreObject(item.url);
    this.altText.fromStoreObject(item.altText);
    this.link.fromStoreObject(item.link);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    const randomSeed = Math.floor(Math.random() * 1000);
    const defaultUrl = `https://picsum.photos/600/800?random=${randomSeed}`;
    // const defaultUrl = "/img/pictures/lighthouse.jpg";
    this.url.setValueOf(Language.universal, defaultUrl);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const image = item as Image;
    this.url.from(image.url);
    this.altText.from(image.altText);
    this.link.from(image.link);
  }

  getMeta(): MetaInfo{
    return Image.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Image();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}