import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { PropertyUrl } from "@/components/shared/property/propertyUrl/PropertyUrl";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Video extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Video", friendlyName: "Video"};

  static readonly nameTitle: string = "Title";
  static readonly nameUrl: string = "URL";

  title = new PropertyString(Video.nameTitle);
  url = new PropertyUrl(Video.nameUrl);

  public getStoreObjectItem(): Object{
    return {
      title: this.title.getStoreObject(),
      url: this.url.getStoreObject(),
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);
    this.url.fromStoreObject(item.url);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    this.url.setValueOf(Language.universal, `https://player.vimeo.com/progressive_redirect/playback/779570098/rendition/1080p/file.mp4?loc=external&signature=a5a67094d26c6a205d408783317b250ef50baf765efbeaac1997043e2edd4aa1`);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const video = item as Video;
    this.title.from(video.title);
    this.url.from(video.url);
  }

  getMeta(): MetaInfo{
    return Video.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Video();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}