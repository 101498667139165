import { reactive } from "vue";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import Style from "./view/default/Style";
import { collectionMeta, typeToItem } from "@/components/shared/registry/All";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Collection<Type extends BaseItem = BaseItem> extends BaseItem{
  static readonly meta: MetaInfo = collectionMeta;

  // Instead of:
  // elements: BaseItem[] = [];
  // Make array reactive here, rather than in the components,
  // so we can still call methods of this class.
  elements = reactive([]) as Type[];

  public getStoreObjectItem(): Object{
    const elementObjects : any[]= [];
    this.elements.forEach((element: Type)=>{
      elementObjects.push(element.getStoreObject());
    });

    return {
      elements: elementObjects,
    };
  }

  public fromStoreObjectItem(item: any){
    this.elements.splice(0); // clear array without losing reactivity
    const elementObjects : any[] = item.elements;
    elementObjects.forEach((elementObject: any)=>{
      const element = typeToItem(elementObject.type);
      element.fromStoreObject(elementObject);
      this.elements.push(element as Type);
    });
  }

  constructor(itemName = "", types: typeof BaseItem[] = []){
    super();
    this.setItemName(itemName);``

    this.setStyle(new Style);
  }

  public getMeta(): MetaInfo{
    return Collection.meta;
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const asCollection = item as Collection<Type>;

    // elements
    this.elements = [];
    for (let i = 0; i<asCollection.getElements().length; ++i){
      this.elements.push(asCollection.getElements()[i].clone() as Type);
    }
  }

  public contains(item: BaseItem): BaseItem[]{
    for (const element of this.elements){
      if (element.ref == item.ref){
        return [element, this];
      }
    }
    for (const element of this.elements){
      const tmp = element.contains(item);
      if (tmp.length>0){
        tmp.push(this);
        return tmp;
      }
    }
    return [];
  }

  public getDefaultInstance(): BaseItem{
    return new Collection();
  }

  public getElements(): Type[]{
    return this.elements;
  }

  public addNewElement(element: Type): void{
    this.elements.push(element);
  }

  public addNewElementAt(element: Type, idx: number): void{
    this.elements.splice(idx, 0, element);
  }

  public removeElementAt(idx: number): void{
    if (-1<idx && idx<this.elements.length){
      this.elements.splice(idx, 1);
    } else {
      throw new Error("Collection.removeElementAt: invalid index.");
    }
  }

  public removeElement(element: Type): void{
    const idx = this.elements.indexOf(element);
    if (idx<0) throw new Error("Collection.removeElement: invalid element.");
    this.removeElementAt(idx);
  }

  private moveElement(from: number, to: number){
    const elementToMove = this.elements.splice(from, 1)[0];
    this.elements.splice(to, 0, elementToMove);
  }

  public moveElementForwards(element: Type){
    const from = this.elements.indexOf(element);
    const to = from - 1;
    if (from>=0 && to>=0){
      this.moveElement(from, to);
    }
  }

  public moveElementBackwards(element: Type){
    const from = this.elements.indexOf(element);
    const to = from + 1;
    if (from>=0 && to<this.elements.length){
      this.moveElement(from, to);
    }
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}