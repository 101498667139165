import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { Collection } from "../../elemental/collection/Collection";
import Style from "./view/default/Style";
import { ProductPreview } from "../productPreview/ProductPreview";
import ProductPreviewsCollectionStyle from "@/components/elemental/collection/view/horizontal/Style"
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class ProductGallery extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "ProductGallery", friendlyName: "Product Gallery"};

  static readonly nameTitle: string = "Title";
  static readonly nameProductRefs: string = "productPreviews";

  title = new PropertyString(ProductGallery.nameTitle);
  products = [] as ProductPreview[];

  public getStoreObjectItem(): Object{
    const productObjects : any[]= [];
    this.products.forEach((product: ProductPreview)=>{
      productObjects.push(product.getStoreObject());
    });

    return {
      title: this.title.getStoreObject(),
      products: productObjects,
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);

    this.products.splice(0); // clear array without losing reactivity
    const productObjects : any[] = item.products;
    productObjects.forEach((productObject: any)=>{
      const productPreview = new ProductPreview();
      productPreview.fromStoreObject(productObject);
      this.products.push(productPreview);
    });
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    this.title.setValueOf(Language.universal, "Product Gallery Title");

    const productPreview1 = new ProductPreview("");
    productPreview1.productRef.setRefId("Poster");

    const productPreview2 = new ProductPreview("");
    productPreview2.productRef.setRefId("Accessories");

    this.products.push(productPreview1);
    this.products.push(productPreview2);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const productGallery = item as ProductGallery;
    this.title.from(productGallery.title);

    for (let i = 0; i<productGallery.products.length; ++i){
      const clonedElement = productGallery.products[i].clone() as ProductPreview;
      this.products.push(clonedElement);
    }
  }

  public contains(item: BaseItem): BaseItem[]{
    for (const product of this.products){
      if (product.ref == item.ref){
        return [product, this];
      }
    }
    return [];
  }

  getMeta(): MetaInfo{
    return ProductGallery.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new ProductGallery();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}