export
enum MessageLevel{
  Debug,
  Info,
  Warning,
  Error
}

export
enum MessageType{
  None,
  Context,
  Layout,
  Storage,
  Network,
}

export default
class Logger{
  static liveTimeWithMillisecond(): string {
    const now = new Date();
    // const year = now.getFullYear();
    // const month = (now.getMonth() + 1).toString().padStart(2, '0');
    // const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const milliseconds = now.getMilliseconds().toString().padStart(3, '0');

    const formattedHours = hours.toString().padStart(2, '0');
    // const formatted_time = `${year}-${month}-${day} ${formatted_hours}:${minutes}:${seconds}.${milliseconds} ${ampm}`;
    const formattedTime = `${formattedHours}:${minutes}:${seconds}.${milliseconds}`;
    return formattedTime;
  }

  static log(level: MessageLevel, type: MessageType, message: string, ...optionalParams: any[]){
    const time = Logger.liveTimeWithMillisecond();
    const text = `${time}[${MessageLevel[level]}][${MessageType[type]}]:${message}`;
    let style = "";
    switch(level){
      case MessageLevel.Warning:
        style = 'color: orange;';
        break;
      case MessageLevel.Error:
        style = 'color: red';
        break;
    }
    if (optionalParams.length>0){
      console.log('%c'+text, style, optionalParams);
    } else {
      console.log('%c'+text, style);
    }
  }
}