export enum Language{
  universal = "universal", // used for creating elements, blocks and pages without specifying language.
  en = "en",
  zh = "zh",
  de = "de",
  jp = "jp",
}

export
const allLanguages = [Language.en, Language.zh, Language.de, Language.jp];

export class LanguageOption{
  current = Language.universal; // what language is selected.

  public from(languageOption: LanguageOption): void{
    this.current = languageOption.current;
  }

  constructor(
    currentLanguage: Language = Language.universal
  ){
    this.current = currentLanguage;
  }

  static ifValid(lang: string): boolean{
    return Object.values(Language).includes(lang as Language);
  }
}