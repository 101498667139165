import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "body", {}, undefined, true),
    ($setup.refContext.mode.inAnyEditMode())
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: $setup.refItem.ref,
          style: {"position":"absolute","bottom":"0","top":"-50px"}
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"top":"-1px","left":"-1px","bottom":"-1px","width":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"top":"-1px","right":"-1px","bottom":"-1px","width":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"right":"-1px","left":"-1px","bottom":"-1px","height":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"top":"-1px","left":"-1px","right":"-1px","height":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true)
  ], 64))
}