import { Page } from '@/components/page/page/Page';
import { Product } from '@/components/product/product/Product';
import { Language } from '@/components/shared/view/LanguageOption';

export default
class Model{
  static caseInsensitiveCompare(a: string, b: string){
    return a.toLowerCase() === b.toLowerCase();
  }

  static pages = [] as Page[];

  static products = [] as Product[];

  static getProduct(id: string): Product{
    for (const product of Model.products) {
      if (product.ref === id || Model.caseInsensitiveCompare(product.urlSlug, id)){
        return product;
      }
    }

    throw new Error(`Model.getProduct: product with id = ${id} not found`);
  }

  static init(){
    const page1 = new Page;
    page1.title.setValueOf(Language.universal, "Home");
    page1.urlSlug = "Home";
    this.pages.push(page1);

    const page2 = new Page;
    page2.title.setValueOf(Language.universal, "Design");
    page2.urlSlug = "Design";
    this.pages.push(page2);
    //
    const product1 = new Product;
    product1.ref = "Poster";
    product1.title.setValueOf(Language.universal, "Poster");
    product1.urlSlug = "Poster";
    this.products.push(product1);

    const product2 = new Product;
    product2.title.setValueOf(Language.universal, "Accessories");
    product2.ref = "Accessories";
    product2.urlSlug = "Accessories";
    this.products.push(product2);
  }
}

Model.init();