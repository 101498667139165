
<script lang="ts">
import MixinProps from '@/components/shared/view/MixinProps';
import { Ref, computed, toRef } from 'vue';
import { setSelectedItem } from '@/components/shared/view/Context';
import { BaseItem } from '../model/BaseItem';

export default {
  mixins: [MixinProps],
  name: 'SelectItemArea',

  setup(props: any) {
    const refItem = toRef(props, 'item') as Ref<BaseItem>;

    const toggleSelection = (event: Event) => {
      event.stopPropagation();
      if (props.context.selection.item != null && refItem.value.ref == props.context.selection.item.ref){
        setSelectedItem(props.context, null);
      } else {
        setSelectedItem(props.context, refItem.value);
      }
    };

    const computedButtonStyle = computed( () => {
      return {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        backgroundColor: "transparent",
        border: "none",
      };
    });

    const computedIfShowButton = computed( () => {
      const selectItem = props.context.mode.selectItem;
      if ( !selectItem )
        return false;

      const nothingSelected = props.context.selection.item == null;
      if ( selectItem && nothingSelected )
        return true;

      const selfNotSelected = !nothingSelected && refItem.value.ref != props.context.selection.item.ref;
      if ( !selfNotSelected )
        return false;

      const childNotSelected = !nothingSelected && !(refItem.value.contains(props.context.selection.item).length>0);
      const ifShowButton = selectItem && selfNotSelected && childNotSelected ;
      return ifShowButton;
    });

    return {
      props,
      toggleSelection,
      computedButtonStyle,
      computedIfShowButton,
    }
  }
}
</script>

<template>
  <button v-if="computedIfShowButton"
    :style="computedButtonStyle"
    v-on:click="(event)=>{toggleSelection(event)}"
  />
</template>