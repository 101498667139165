import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { StyleVariableString, EnumPair } from "./StyleVariableString";
import { StyleVariableColor } from "./StyleVariableColor";
import StyleBorder from "./StyleBorder";
import Context from "../view/Context";
import StylePadding from "./StylePadding";

export default
class StyleContained extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "StyleContained", friendlyName: "Background"};

  static readonly nameBackgroundMode: string = "Mode";
  static readonly nameColor: string = "Color";
  static readonly nameImage: string = "Image";
  static readonly nameGradient: string = "Gradient";
  static readonly nameOpacity: string = "Opacity";
  static readonly nameBackdropFilter: string = "Filter";
  static readonly nameMinWidth: string = "Background Min Width";
  static readonly nameMaxWidth: string = "Background Max Width";
  static readonly nameContentMaxWidth: string = "Content Max Width";
  static readonly nameShadow: string = "Shadow";
  static readonly nameBorder: string = "Border";
  static readonly namePadding: string = "Padding";
  static readonly nameAlignment: string = "Alignment";

  public backgroundMode = new StyleVariableString(StyleContained.nameBackgroundMode, "");
  public color = new StyleVariableColor(StyleContained.nameColor, "#FFFFFF00");
  public image = new StyleVariableString(StyleContained.nameImage, "");
  public gradient = new StyleVariableString(StyleContained.nameGradient, "");
  public opacity = new StyleVariableString(StyleContained.nameOpacity, "1");
  public backdropFilter = new StyleVariableString(StyleContained.nameBackdropFilter, "blur(6px)");
  public minWidth = new StyleVariableString(StyleContained.nameMinWidth, "none");
  public maxWidth = new StyleVariableString(StyleContained.nameMaxWidth, "none");
  public contentMaxWidth = new StyleVariableString(StyleContained.nameContentMaxWidth, "none");
  public shadow = new StyleVariableString(StyleContained.nameShadow, "0 0 0px 0px #000");
  public border = new StyleBorder(StyleContained.nameBorder);
  public padding = new StylePadding();
  public alignment = new StyleVariableString(StyleContained.nameAlignment);

  public getStoreObjectItem(): Object{
    return {
      backgroundMode: this.backgroundMode.getStoreObject(),
      color: this.color.getStoreObject(),
      image: this.image.getStoreObject(),
      gradient: this.gradient.getStoreObject(),
      opacity: this.opacity.getStoreObject(),
      backdropFilter: this.backdropFilter.getStoreObject(),
      minWidth: this.minWidth.getStoreObject(),
      maxWidth: this.maxWidth.getStoreObject(),
      contentMaxWidth: this.contentMaxWidth.getStoreObject(),
      shadow: this.shadow.getStoreObject(),
      border: this.border.getStoreObject(),
      padding: this.padding.getStoreObject(),
      alignment: this.alignment.getStoreObject(),
    };
  }

  public fromStoreObjectItem(item: any){
    this.backgroundMode.fromStoreObject(item.backgroundMode);
    this.color.fromStoreObject(item.color);
    this.image.fromStoreObject(item.image);
    this.gradient.fromStoreObject(item.gradient);
    this.opacity.fromStoreObject(item.opacity);
    this.backdropFilter.fromStoreObject(item.backdropFilter);
    this.minWidth.fromStoreObject(item.minWidth);
    this.maxWidth.fromStoreObject(item.maxWidth);
    this.contentMaxWidth.fromStoreObject(item.contentMaxWidth);
    this.shadow.fromStoreObject(item.shadow);
    this.border.fromStoreObject(item.border);
    this.padding.fromStoreObject(item.padding);
    this.alignment.fromStoreObject(item.alignment);
  }

  constructor(){
    super();
    this.setItemName("StyleContained");

    this.backgroundMode.enums = [
      new EnumPair("Color", "Color"),
      new EnumPair("Image", "Image"),
      new EnumPair("Gradient", "Gradient"),
    ];
    this.backgroundMode.enumOnly=true;
    this.backgroundMode.setEnum("Color");

    this.gradient.enums = [
      new EnumPair("Blue", "linear-gradient(45deg, #7B6AB9 10%, #8BC4DB 90%)"),
      new EnumPair("Sunset", "linear-gradient(45deg, #9587E2 10%, #DA889B 90%)"),
      new EnumPair("Galaxy", "linear-gradient(45deg, #D2735E 10%, #7454CC 90%)"),
      new EnumPair("Lake", "linear-gradient(45deg, #5D79B6 20%, #5BA07A 80%)"),
      new EnumPair("Snow", "linear-gradient(145deg, #FFFFFF, #E6E6E6)"),
    ];
    this.gradient.setEnum("Sunset");

    this.opacity.enums = [
      new EnumPair("0%", "0"),
      new EnumPair("50%", "0.5"),
      new EnumPair("100%", "1"),
    ];

    this.backdropFilter.enums = [
      new EnumPair("None", "none"),
      new EnumPair("Blur", "blur(6px)")
    ];
    this.backdropFilter.setEnum("None");

    this.shadow.enums = [
      new EnumPair("None", ""),
      new EnumPair("Soft", "20px 20px 60px #00000044"),
      new EnumPair("Hard", "8px 8px 16px #00000022"),
      new EnumPair("Glow", "inset 0 0 50px #fff, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff")
    ]
    this.shadow.setEnum("None");

    this.alignment.enums = [
      new EnumPair("Start", "Start"),
      new EnumPair("Center", "Center"),
      new EnumPair("End", "End")
    ];
    this.alignment.setEnum("Center");
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const styleContainer = item as StyleContained;
    this.backgroundMode.from(styleContainer.backgroundMode);
    this.color.from(styleContainer.color);
    this.image.from(styleContainer.image);
    this.gradient.from(styleContainer.gradient);
    this.opacity.from(styleContainer.opacity);
    this.backdropFilter.from(styleContainer.backdropFilter);
    this.minWidth.from(styleContainer.minWidth);
    this.maxWidth.from(styleContainer.maxWidth);
    this.contentMaxWidth.from(styleContainer.contentMaxWidth);
    this.shadow.from(styleContainer.shadow);
    this.border.from(styleContainer.border);
    this.padding.from(styleContainer.padding);
    this.alignment.from(styleContainer.alignment);
  }

  getMeta(): MetaInfo{
    return StyleContained.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new StyleContained();
  }

  public getContainerPredefinedStyleObject(context: Context){
    return {
      display: 'flex',
      alignItems: this.alignment.value,
      justifyContent: 'center',

      ...this.border.getPredefinedStyleObject(context),
      borderColor: 'transparent',
    };
  }

  public getContentPredefinedStyleObject(context: Context){
    return {
      width: '100%',
      // maxWidth: this.maxWidth.getValue(),
      maxWidth: this.contentMaxWidth.getValue(),
      minWidth: this.minWidth.getValue(),

      ...this.padding.getPredefinedStyleObject(context),
    };
  }

  public getBackgroundFillPredefinedStyleObject(context: Context): object{
    let backgroundFill = {};
    switch(this.backgroundMode.value){
      case "Image":
        backgroundFill = {backgroundImage: this.image.getValue()};
        break;
      case "Gradient":
        backgroundFill = {backgroundImage: this.gradient.getValue()};
        break;
      case "Color":
      default:
        backgroundFill = {backgroundColor: this.color.getColor(context)};
    }
    return {
      ...backgroundFill,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      opacity: this.opacity.getValue(),
      backdropFilter: this.backdropFilter.getValue(),
      webkitBackdropFilter: this.backdropFilter.getValue()
    }
  }

  public getBackgroundPredefinedStyleObject(context: Context){
    const background = this.getBackgroundFillPredefinedStyleObject(context);

    return{
      ...background,

      boxShadow: this.shadow.getValue(),

      ...this.border.getPredefinedStyleObject(context),

      minWidth: this.minWidth.getValue(),
      maxWidth: this.maxWidth.getValue(),
      margin: 'auto',
    }
  }
}