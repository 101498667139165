<script lang="ts">
import MixinProps from '@/components/shared/view/MixinProps';
import { BaseItem } from "@/components/shared/model/BaseItem";
import Context from "@/components/shared/view/Context"
import ComponentFactory from "@/components/ComponentFactory";
import { Ref, toRef } from 'vue';

export default {
  name: 'ComponentWrapper',
  mixins: [MixinProps],
  setup(props: any){
    const refItem = toRef(props, 'item') as Ref<BaseItem>;

    const getComponent = (item: BaseItem, context: Context) => {
      return ComponentFactory.getComponent(item, context);
    };
    return{
      props,
      refItem,
      getComponent
    }
  },
}
</script>

<template>
  <component v-bind:is="getComponent(refItem, props.context)" :item="refItem" :context="props.context"></component>
</template>

<style scoped>
</style>