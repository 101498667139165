import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Icon extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Icon", friendlyName: "Icon"};

  static readonly nameName: string = "Name";
  name = new PropertyString(Icon.nameName);

  public getStoreObjectItem(): Object{
    return {
      name: this.name.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.name.fromStoreObject(item.name);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const icon = item as Icon;
    this.name.from(icon.name);
  }

  getMeta(): MetaInfo{
    return Icon.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Icon();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}