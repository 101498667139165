import type { App } from "@/components/app/app/App";
import { BaseItem } from "@/components/shared/model/BaseItem";
import { v4 as uuid } from "uuid";
import { Language, LanguageOption } from '@/components/shared/view/LanguageOption';

import { watch } from "vue";
import { useRoute } from 'vue-router';
import router from '@/Router';
import type { PageRef } from "@/components/page/pageRef/PageRef";
import type { FluidContainer } from "@/components/elemental/fluidContainer/FluidContainer";
import { FluidElement } from "@/components/elemental/fluidContainer/view/shared/FluidElement";
import Logger, { MessageLevel, MessageType } from "@/shared/Logger";

export class Mode{
  editContent : boolean = false;
  editTextInline : boolean = false;
  editLayout : boolean = false;
  selectItem : boolean = false;

  public from(mode: Mode): void{
    this.editContent = mode.editContent;
    this.editTextInline = mode.editTextInline;
    this.editLayout = mode.editLayout;
    this.selectItem = mode.selectItem;
  }

  constructor(
    editContent: boolean = false,
    editTextInline: boolean = false,
    editLayout: boolean = false,
    selectItem: boolean = false,
  ){
    this.editContent = editContent;
    this.editTextInline = editTextInline;
    this.editLayout = editLayout;
    this.selectItem = selectItem;
  }

  inAnyEditMode(): boolean{
    return this.editContent || this.editContent || this.editTextInline;
  }

  itemSelected(): boolean{
    return this.inAnyEditMode() && this.selectItem;
  }
}

export class DisplayOption{
  scale = 1;
  pageSafeAreaInsetTop = 0; //px

  public from(displayOption: DisplayOption): void{
    this.scale = displayOption.scale;
    this.pageSafeAreaInsetTop = displayOption.pageSafeAreaInsetTop;
  }

  constructor(
    scale: number = 1,
    pageSafeAreaInsetTop: number = 0,
  ){
    this.scale = scale;
    this.pageSafeAreaInsetTop = pageSafeAreaInsetTop;
  }
}

export class Selection{
  app : App|undefined = undefined;
  pageRef: PageRef|null = null;
  page: BaseItem|null = null;
  item : BaseItem|null = null;

  clipboard : BaseItem|null = null;

  public from(selection: Selection): void{
    this.app = selection.app;
    this.pageRef = selection.pageRef;
    this.page = selection.page;
    this.item = selection.item;
    this.clipboard = selection.clipboard;
  }

  constructor(
    app: App|undefined = undefined,
    pageRef: PageRef|null = null,
    page: BaseItem|null = null,
    item: BaseItem|null = null,
    clipboard: BaseItem|null = null
  ){
    this.app = app;
    this.pageRef = pageRef;
    this.page = page;
    this.item = item;
    this.clipboard = clipboard;
  }
}

export abstract class BaseRequest{
  public id: string = uuid();

  public abstract from(request: BaseRequest): void;
}

export class RequestEditType extends BaseRequest{
  public type: string = "";

  public from(requestEditType: RequestEditType): void{
    this.type = requestEditType.type;
  }

  constructor(
    type: string = ""
  ){
    super();
    this.type = type;
  }
}

export class RequestEditFluid extends BaseRequest{
  public container : undefined | FluidContainer;
  public element : undefined | FluidElement;

  public from(requestEditFluid: RequestEditFluid): void{
    this.container = requestEditFluid.container;
    this.element = requestEditFluid.element;
  }

  constructor(
    container: undefined | FluidContainer = undefined,
    element: undefined | FluidElement = undefined
  ){
    super();
    this.container = container;
    this.element = element;
  }
}

export class RequestAddItem extends BaseRequest{
  public type: string = "";
  public callback: Function | undefined;

  public from(requestAddItem: RequestAddItem): void{
    this.type = requestAddItem.type;
    this.callback = requestAddItem.callback;
  }

  constructor(
    type: string,
    callback : Function | undefined
  ){
    super();
    this.type = type;
    this.callback = callback;
  }
}

export class RequestMenuPopup extends BaseRequest{
  show = false;

  public from(requestMenuPopup: RequestMenuPopup): void{
    this.show = requestMenuPopup.show;
  }

  constructor(
    show: boolean
  ){
    super();
    this.show = show;
  }
}

export class UiState{
  private lastRequestEditType : RequestEditType = new RequestEditType();
  private lastRequestEditFluid : RequestEditFluid = new RequestEditFluid();
  private lastRequestAddItem : RequestAddItem = new RequestAddItem("Item", undefined);

  private lastRequestMenuPopup : RequestMenuPopup = new RequestMenuPopup(false);

  public requestEditType(type: string){
    this.lastRequestEditType = new RequestEditType(type);
  }

  public requestEditFluid(container: undefined | FluidContainer, element: undefined | FluidElement){
    this.lastRequestEditFluid = new RequestEditFluid(container, element);
  }

  public requestAddItem(type: string, callback: Function | undefined){
    this.lastRequestAddItem = new RequestAddItem(type, callback);
  }

  public requestMenuPopup(dismiss: boolean){
    this.lastRequestMenuPopup = new RequestMenuPopup(dismiss);
  }

  public getLastRequestEditType() : RequestEditType{
    return this.lastRequestEditType;
  }

  public getLastRequestEditFluid() : RequestEditFluid{
    return this.lastRequestEditFluid;
  }

  public getLastRequestAddItem() : RequestAddItem{
    return this.lastRequestAddItem;
  }

  public getLastRequestMenuPopup() : RequestMenuPopup{
    return this.lastRequestMenuPopup;
  }

  public from(uiState: UiState): void{
    this.lastRequestEditType.from(uiState.lastRequestEditType);
    this.lastRequestAddItem.from(uiState.lastRequestAddItem);
    this.lastRequestMenuPopup.from(uiState.lastRequestMenuPopup);
  }

  constructor(
    requestEditType: RequestEditType = new RequestEditType(),
  ){
    this.lastRequestEditType = requestEditType;
  }
}

export default class Context{
  mode = new Mode(false, false, false, false);

  languageOption = new LanguageOption();

  displayOption = new DisplayOption(1);
  selection = new Selection(undefined, null, null);
  uiState = new UiState();

  remotePageStoreObject = null as null | Object;

  public from(context: Context): void{
    this.mode.from(context.mode);
    this.displayOption.from(context.displayOption);
    this.selection.from(context.selection);
    this.uiState.from(context.uiState);
  }

  constructor(
    mode: Mode = new Mode(),

    languageOption: LanguageOption = new LanguageOption(),
    displayOption: DisplayOption = new DisplayOption(),
    selection: Selection = new Selection())
  {
    this.mode = mode;

    this.languageOption = languageOption;
    this.displayOption = displayOption;
    this.selection = selection;
  }

  setSelectedItem (item: BaseItem) {
    this.selection.item = item;
  }

  getSelectedItem () : BaseItem | null{
    return this.selection.item;
  }

  setClipboard (item: BaseItem) {
    this.selection.clipboard = item.clone();
  }

  getClipboard () : BaseItem | null{
    return this.selection.clipboard;
  }

  watchQuery(){
    const route = useRoute();
    watch(
      () => route.query.lang,
      () => {
        let lang = route.query.lang as Language
        if (lang === undefined || !LanguageOption.ifValid(lang)){
          Logger.log(MessageLevel.Warning, MessageType.Context, `Context: invalid language in query: ${lang}`);
          lang = this.selection.app!.defaultLanguage;
        }
        Logger.log(MessageLevel.Info, MessageType.Context, `Context: language set to: ${lang}`);
        this.languageOption.current = lang as Language;
      },
      { immediate: true }
    );
  }

  setLanguage(language: Language){
    router.push({ query: { lang: language }});
  }
}

export const setSelectedItem = (context: Context, item: BaseItem | null) => {
  context.selection.item = item;
}