<script lang="ts">
import MixinProps from '@/components/shared/view/MixinProps';
import { GlobalZIndex } from '@/shared/GlobalVariables';
import { BaseItem } from '../model/BaseItem';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';

export default {
  mixins: [MixinProps],
  name: "ComponentContainer",
  setup(props: any) {
    const { getRefItem, getRefContext } = useAddReactivity();
    const refItem = getRefItem<BaseItem>(props);
    const refContext = getRefContext(props);

    const ifHighlight = ()=>{
      return refContext.value.mode.inAnyEditMode() && refContext.value.mode.selectItem &&
        refItem.value != null && refContext.value.selection.item != null &&
        refItem.value.ref == refContext.value.selection.item.ref;
    }

    const zIndexEditorSelectionHighlight = GlobalZIndex.EditorSelectionHighlight;

    return {
      refItem,
      refContext,
      ifHighlight,
      zIndexEditorSelectionHighlight,
    };
  },
};
</script>

<template>
  <!-- <div ondragstart="return false;" class="ComponentContainer InheritSize Unselectable"> -->
    <slot name="body"/>
    <div v-if="refContext.mode.inAnyEditMode()" :id="refItem.ref" style="position: absolute; bottom: 0; top: -50px"/>
    <div v-if="ifHighlight()" class="HighlightLine ColorControlElement" style="top: -1px; left: -1px; bottom: -1px; width: 2px" :style="{zIndex: zIndexEditorSelectionHighlight}"/>
    <div v-if="ifHighlight()" class="HighlightLine ColorControlElement" style="top: -1px; right: -1px; bottom: -1px; width: 2px" :style="{zIndex: zIndexEditorSelectionHighlight}"/>
    <div v-if="ifHighlight()" class="HighlightLine ColorControlElement" style="right: -1px; left: -1px; bottom: -1px; height: 2px" :style="{zIndex: zIndexEditorSelectionHighlight}"/>
    <div v-if="ifHighlight()" class="HighlightLine ColorControlElement" style="top: -1px; left: -1px; right: -1px; height: 2px" :style="{zIndex: zIndexEditorSelectionHighlight}"/>
  <!-- </div> -->
</template>

<style scoped>

.ComponentContainer{
  position: relative;
}

.HighlightLine{
  position: absolute;
}
</style>